// BlackBox Control colors

// primary

$light-primary: #46b978;
$light-primary-contrast: #ffffff;

$dark-primary: #46b978;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #46b978;
$light-accent-contrast: #ffffff;

$dark-accent: #46b978;
$dark-accent-contrast: #fff;

// common

$page-background: #46b9782f;
