/* IMPORTANT */
/* Do not change below variable values */

/* START - scss variables */

$light-background: #f1f5f9;
$light-background-contrast: #ffffff;
$dark-background: #303030;
$dark-background-contrast: #424242;

// success
$light-success: #3c763d;
$light-success-background: #dff0d8;

$dark-success: #ffffff;
$dark-success-background: #3c763d;

// error
$light-error: #be3d3b;
$light-error-background: #f2dede;

$dark-error: #ffffff;
$dark-error-background: #be3d3b;

// info
$light-info: #303030;
$light-info-background: #f1f5f9;

$dark-info: #ffffff;
$dark-info-background: #303030;

// warn
$light-warn: #856404;
$light-warn-background: #fff4be;

$dark-warn: #333333;
$dark-warn-background: #ffbf00;

$black-color: #000000;
$white-color: #ffffff;
$amber-color: #ffbf00;
$orange-color: #ff8c00;

$light-border: #dedede;
$dark-border: #ffffff1f;

$tooltip-background-color: #272727;
$snackbar-background-color: #272727;
$page-subheader-color: #434343;

$card-shadow: #0000001a 0px 4px 4px 0px;

$light-text-link: #005dbb;
$dark-text-link: #91bad6;

$light-shimmer: #ecf1f6;
$dark-shimmer: #535050;

$dark-text: #cccccc;
$light-text: #575757;
/* END - scss variables */

/* START - notification floating */
$notification-floating-background-color-light: #3f51b5;
$notification-floating-background-color-dark: #343e48;
/* END - notification floating */
